<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>

                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Gestión de client feedback</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                        <b-form @submit.stop.prevent="handleSubmit(registrarClientFeedback)">
                                            <b-row>
                                                <b-col md="12">
                                                    <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Normas:" class="mb-2">
                                                            <v-select multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': dataClientFeedback.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataClientFeedback.normas" :options="listaNormas">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <!-- <b-col md="6">
                                                    <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Cliente:" class="mb-2">
                                                            <v-select :reduce="listaClientes => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': dataClientFeedback.idAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataClientFeedback.idAuditoria" :options="listaClientes">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                                <b-col md="6">
                                                    <validation-provider name="contrato Nro" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Contrato Nro:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nro de contrato" v-model.lazy="dataClientFeedback.nContrato"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="tipo de auditoría" :rules="!isTemplate ? 'required':''" v-slot="{errors}">
                                                        <b-form-group label="Tipo de auditoría:" class="mb-2">
                                                            <v-select multiple :reduce="listaTipoAuditoria => listaTipoAuditoria.idTipoAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': dataClientFeedback.tipoAuditoria.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataClientFeedback.tipoAuditoria" :options="listaTipoAuditoria">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="criterios de la auditoría" v-slot="validationContext">
                                                        <b-form-group label="Criterios de la auditoría:" class="mb-2">
                                                            <b-form-textarea rows="2" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataClientFeedback.criteriosAuditoria" placeholder="Ingrese los criterios de la auditoría"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="Alcance de la certificación" v-slot="validationContext">
                                                        <b-form-group label="Alcance de la certificación:" class="mb-2">
                                                            <b-form-textarea rows="2" max-rows="6" :state="getValidationState(validationContext)" v-model.lazy="dataClientFeedback.alcanceCertificacion" placeholder="Ingrese el alcance de la certificación"></b-form-textarea>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="20%" rowspan="2" class="valign-middle text-center">
                                                                    <span class="text-muted h6"><b>Fecha de auditoría</b></span>
                                                                </b-td>
                                                                <b-td width="40%">
                                                                    <b-form-group label="Dia:" class="mb-2">
                                                                        <b-form-input placeholder="Ingrese el dia" type="date" v-model.lazy="dataClientFeedback.fechaAuditoria[0].dia"></b-form-input>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center valign-middle" rowspan="2">
                                                                    <b-form-checkbox-group plain v-model.lazy="dataClientFeedback.fechaAuditoria[0].check" :options="[{value: '1',text:'JAS-ANZ'},{value: '2',text:'IAS'}]" />
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    <b-form-group label="Fecha de cierre:" class="mb-2">
                                                                        <b-form-input placeholder="Ingrese la fecha de cierre" type="date" v-model.lazy="dataClientFeedback.fechaAuditoria[0].fechaCierre"></b-form-input>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="16%" class="valign-middle text-center">
                                                                    <span class="text-muted h6"><b>Miembro (s) del equipo de auditoría</b></span>
                                                                </b-td>
                                                                <b-td width="16%">
                                                                    <b-form-group label="Auditor lider:" class="mb-2">
                                                                        <b-form-input placeholder="Ingrese el auditor lider" @change="dataClientFeedback.equipoAuditoria[0].auditorLider = $event" :value="dataClientFeedback.equipoAuditoria[0].auditorLider"></b-form-input>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td width="16%">
                                                                    <b-form-group label="Auditor (2):" class="mb-2">
                                                                        <b-form-input placeholder="Ingrese el auditor" @change="dataClientFeedback.equipoAuditoria[0].auditor2 = $event" :value="dataClientFeedback.equipoAuditoria[0].auditor2"></b-form-input>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td width="16%">
                                                                    <b-form-group label="Auditor (3):" class="mb-2">
                                                                        <b-form-input placeholder="Ingrese el auditor" @change="dataClientFeedback.equipoAuditoria[0].auditor3 = $event" :value="dataClientFeedback.equipoAuditoria[0].auditor3"></b-form-input>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td width="16%">
                                                                    <b-form-group label="Auditor (4):" class="mb-2">
                                                                        <b-form-input placeholder="Ingrese el auditor" @change="dataClientFeedback.equipoAuditoria[0].auditor4 = $event" :value="dataClientFeedback.equipoAuditoria[0].auditor4"></b-form-input>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td width="16%">
                                                                    <b-form-group label="Experto técnico:" class="mb-2">
                                                                        <b-form-input placeholder="Ingrese el experto técnico" @change="dataClientFeedback.equipoAuditoria[0].expertoTecnico = $event" :value="dataClientFeedback.equipoAuditoria[0].expertoTecnico"></b-form-input>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="6">
                                                                    <quill-editor :options="editorOption" v-model.lazy="dataClientFeedback.acotacion">
                                                                    </quill-editor>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col md="12" class="mt-2">
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="10%" class="valign-middle text-center">
                                                                    <span class="text-muted h6"><b>Si. No</b></span>
                                                                </b-td>
                                                                <b-td width="60%" class="text-center">
                                                                    <span class="text-muted h6"><b>Comentarios sobre el equipo de auditoría</b></span>
                                                                </b-td>
                                                                <b-td width="30%" class="text-center">
                                                                    <span class="text-muted h6"><b>Comentarios de los clientes</b></span>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr v-for="(data,index) in dataClientFeedback.contenido" :key="index">
                                                                <b-td class="text-center">
                                                                    <span class="text-muted h6">{{index+1}}</span>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-input @change="data.texto = $event" :value="data.texto"></b-form-input>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group plain v-model="data.radio" :options="data.radioOptions" />
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="2">
                                                                    <b-form-group label="Cualquier otra retroalimentación para mejorar y / o retroalimentación sobre un miembro individual del equipo de auditoría: " class="mb-2">
                                                                        <b-form-textarea rows="2" max-rows="6" :value="dataClientFeedback.otroFeedback" placeholder="Escribe aqui..." @change="dataClientFeedback.otroFeedback = $event"></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-group label="Firma:">
                                                                        <b-input-group>
                                                                            <b-input-group-prepend v-if="dataClientFeedback.urlFirma1 != ''">
                                                                                <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(dataClientFeedback.urlFirma1)" v-c-tooltip="'Descargar'">
                                                                                    <i class="fas fa-download fa-xs"></i>
                                                                                </b-button>
                                                                            </b-input-group-prepend>
                                                                            <b-form-file ref="file1" class="text-left" v-model.lazy="firma1" v-on:change="handleFileUpload('1',$event)" :placeholder="dataClientFeedback.nombreFirma1 || 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="dataClientFeedback.nombreFirma1 || 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                        </b-input-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="3">
                                                                    <b-form-group label="Revisión por IQC: " class="mb-2">
                                                                        <b-form-input :value="dataClientFeedback.revisionPorIQC" placeholder="Ingrese la revisión por IQC" @change="dataClientFeedback.revisionPorIQC = $event" />
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="2">
                                                                    <b-form-group label="Revisión por: " class="mb-2">
                                                                        <b-form-input :value="dataClientFeedback.revisadoPor" placeholder="Ingrese la revisión por" @change="dataClientFeedback.revisadoPor = $event" />
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td>
                                                                    <b-form-group label="Inquietudes, si las hay, comunicadas al líder del equipo de auditoría / auditor individual">
                                                                        <b-form-radio-group plain v-model.lazy="dataClientFeedback.radioRevision" :options="[{value: '1',text:'SI'},{value: '2',text:'NO'}]" />
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td colspan="3">
                                                                    <b-row>
                                                                        <b-col md="6">
                                                                            <b-form-group label="Firma:">
                                                                                <b-input-group>
                                                                                    <b-input-group-prepend v-if="dataClientFeedback.urlFirma2 != ''">
                                                                                        <b-button variant="dark" class="px-2 py-0" @click="descargarDocumento(dataClientFeedback.urlFirma2)" v-c-tooltip="'Descargar'">
                                                                                            <i class="fas fa-download fa-xs"></i>
                                                                                        </b-button>
                                                                                    </b-input-group-prepend>
                                                                                    <b-form-file ref="file2" class="text-left" v-model.lazy="firma2" v-on:change="handleFileUpload('2',$event)" :placeholder="dataClientFeedback.nombreFirma2 || 'Elija un archivo o arrástrelo aquí...'" :drop-placeholder="dataClientFeedback.nombreFirma2 || 'Elija un archivo o arrástrelo aquí...'" browse-text="Subir"></b-form-file>
                                                                                </b-input-group>
                                                                            </b-form-group>
                                                                        </b-col>
                                                                        <b-col md="6">
                                                                            <b-form-group label="Fecha: " class="mb-2">
                                                                                <b-form-input :value="dataClientFeedback.fecha" type="date" @change="dataClientFeedback.fecha = $event" />
                                                                            </b-form-group>
                                                                        </b-col>
                                                                    </b-row>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 2' }" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        <i class="fas fa-save"></i> Guardar
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </validation-observer>
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import {
    quillEditor
} from 'vue-quill-editor'

export default {
    components: {
        quillEditor,
    },
    data() {
        return {
            isTemplate: true,
            editorOption: {
                // theme: 'bubble',
                placeholder: 'Escriba aqui...',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        /* ['blockquote', 'code-block'], */
                        [{
                            'header': 1
                        }, {
                            'header': 2
                        }],
                        [{
                            'list': 'ordered'
                        }, {
                            'list': 'bullet'
                        }],
                        [{
                            'script': 'sub'
                        }, {
                            'script': 'super'
                        }],
                        [{
                            'indent': '-1'
                        }, {
                            'indent': '+1'
                        }],
                        [{
                            'direction': 'rtl'
                        }],
                        [{
                            'size': ['small', false, 'large', 'huge']
                        }],
                        [{
                            'header': [1, 2, 3, 4, 5, 6, false]
                        }],
                        [{
                            'font': []
                        }],
                        [{
                            'color': []
                        }, {
                            'background': []
                        }],
                        [{
                            'align': []
                        }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],
                    syntax: {
                        highlight: text => hljs.highlightAuto(text).value
                    }
                }
            },

            dataClientFeedback: {
                idClienteFeedback: '',
                idCliente: null,

                idAuditoria: null,
                normas: [],
                nContrato: '',
                tipoAuditoria: [],
                alcanceCertificacion: '',
                fechaAuditoria: [{
                    dia: '',
                    fechaCierre: '',
                    check: [],
                }],
                equipoAuditoria: [{
                    auditorLider: '',
                    auditor2: '',
                    auditor3: '',
                    auditor4: '',
                    expertoTecnico: '',
                }],
                acotacion: '<p><em style="color: rgb(0, 0, 10);">IQC desea agradecer a su organización por brindar la oportunidad de evaluar el Sistema de Gestión según el estándar mencionado anteriormente y asegurarle nuestros servicios de valor agregado en todo momento. Para identificar y planificar mejoras adicionales, le solicitamos que complete este formulario de comentarios y nos lo devuelva al final de la auditoría o dentro de una semana de la finalización de nuestra auditoría. También le aseguramos que el contenido de esta encuesta se mantendrá en estricta confidencialidad y de ninguna manera afectará la decisión de certificación. Proporcione información sobre el desempeño de cada uno de los equipos de auditoría para que podamos mejorar los servicios. Envíe sus comentarios al final de la auditoría Tel .: + 91 (80) 41280347 o envíe un correo electrónico a: iqccorporate@iqcglobal.com</em></p>',

                contenido: [{
                        texto: '¿Hubo alguna ambigüedad y retraso en la comunicación?',
                        radio: 1,
                        radioOptions: [{
                            text: 'Si',
                            value: 1
                        }, {
                            text: 'No',
                            value: 2
                        }, {
                            text: 'Observación',
                            value: 3
                        }, ]
                    },
                    {
                        texto: '¿El equipo de auditoría completó la auditoría de todos los procesos planificados? ',
                        radio: 1,
                        radioOptions: [{
                            text: 'Si',
                            value: 1
                        }, {
                            text: 'No',
                            value: 2
                        }, {
                            text: 'Observación',
                            value: 3
                        }, ]
                    }, {
                        texto: '¿El líder del equipo proporcionó información completa relacionada con la auditoría durante la reunión de apertura y cierre? ',
                        radio: 1,
                        radioOptions: [{
                            text: 'Si',
                            value: 1
                        }, {
                            text: 'No',
                            value: 2
                        }, {
                            text: 'Observación',
                            value: 3
                        }, ]
                    }, {
                        texto: '¿Es el equipo auditor técnicamente competente? ',
                        radio: 1,
                        radioOptions: [{
                            text: 'Si',
                            value: 1
                        }, {
                            text: 'No',
                            value: 2
                        }, {
                            text: 'Observación',
                            value: 3
                        }, ]
                    }, {
                        texto: '¿Los miembros del equipo de auditoría demostraron una actitud profesional durante la auditoría?',
                        radio: 1,
                        radioOptions: [{
                            text: 'Si',
                            value: 1
                        }, {
                            text: 'No',
                            value: 2
                        }, {
                            text: 'Observación',
                            value: 3
                        }, ]
                    }, {
                        texto: '¿El informe de auditoría y la descripción de las NCR son claros y sin ambigüedad? ',
                        radio: 1,
                        radioOptions: [{
                            text: 'Si',
                            value: 1
                        }, {
                            text: 'No',
                            value: 2
                        }, {
                            text: 'Observación',
                            value: 3
                        }, ]
                    }, {
                        texto: 'No hubo interrupciones en la auditoría remota, la intensidad de la señal fue satisfactoria, la comunicación fue clara sin ruido de fondo y la auditoría fue interactiva para que todos los auditados lograran los objetivos',
                        radio: 1,
                        radioOptions: [{
                            text: 'Si',
                            value: 1
                        }, {
                            text: 'No',
                            value: 2
                        }, {
                            text: 'Observación',
                            value: 3
                        }, {
                            text: 'No Aplica',
                            value: 4
                        }, ]
                    },
                ],

                otroFeedback: '',
                revisionPorIQC: '',
                radioRevision: 1,
                revisadoPor: 'AMSS / ECC',
                fecha: null,
                urlFirma1: '',
                nombreFirma1: '',
                urlFirma2: '',
                nombreFirma2: '',
            },
            firma1: null,
            firma2: null,

            listaClientes: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            listaMetodologiaAuditoria: [{
                idMetodologiaAuditoria: 1,
                descripcion: 'Auditoría in situ',
            }, {
                idMetodologiaAuditoria: 2,
                descripcion: 'Auditoría remota',
            }],

            listaTipoAuditoria: [{
                idTipoAuditoria: 1,
                descripcion: 'IA-Estadio-II',
            }]
        }

    },
    methods: {
        handleFileUpload(ident, e) {
            if (!e.target.files[0]) {
                return;
            } else {
                if (!e.target.files[0].name.match(/\.(xlsx|xls|csv|pdf|doc|docx|ppt|pptx|png|jpg|jpeg)$/i)) {
                    this.swat('error', "Formato de archivo no admitido.");
                    this.$refs[`file${ident}`].reset();
                    return;
                }
                if (e.target.files[0].size > 20971520) {
                    this.swat('error', "El tamaño del archivo no debe ser superior a 20MB");
                    this.$refs[`file${ident}`].reset();
                    return;
                }
            }
        },
        descargarDocumento(url) {
            window.open(url);
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        obtenerClientFeedback() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-client-feedback-s2", {
                    params: {
                        idClienteFeedback: me.$route.params.id,
                        idCliente: me.dataClientFeedback.idCliente,
                    }
                })
                .then(function (response) {

                    me.dataClientFeedback.idClienteFeedback = response.data[0].idClienteFeedback;
                    me.dataClientFeedback.idCliente = response.data[0].idCliente;
                    me.dataClientFeedback.idAuditoria = response.data[0].idAuditoria;
                    me.dataClientFeedback.normas = JSON.parse(response.data[0].normas);
                    me.dataClientFeedback.nContrato = response.data[0].nContrato;
                    me.dataClientFeedback.tipoAuditoria = JSON.parse(response.data[0].tipoAuditoria);
                    me.dataClientFeedback.alcanceCertificacion = response.data[0].alcanceCertificacion;
                    me.dataClientFeedback.fechaAuditoria = JSON.parse(response.data[0].fechaAuditoria);
                    me.dataClientFeedback.equipoAuditoria = JSON.parse(response.data[0].equipoAuditoria);
                    me.dataClientFeedback.acotacion = response.data[0].acotacion;
                    me.dataClientFeedback.contenido = JSON.parse(response.data[0].contenido);
                    me.dataClientFeedback.otroFeedback = response.data[0].otroFeedback;
                    me.dataClientFeedback.revisionPorIQC = response.data[0].revisionPorIQC;
                    me.dataClientFeedback.radioRevision = response.data[0].radioRevision;
                    me.dataClientFeedback.revisadoPor = response.data[0].revisadoPor;
                    me.dataClientFeedback.fecha = response.data[0].fecha;
                    me.dataClientFeedback.urlFirma1 = response.data[0].urlFirma1;
                    me.dataClientFeedback.nombreFirma1 = response.data[0].nombreFirma1;
                    me.dataClientFeedback.urlFirma2 = response.data[0].urlFirma2;
                    me.dataClientFeedback.nombreFirma2 = response.data[0].nombreFirma2;
                    if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.dataClientFeedback.isPack = true;
                        me.dataClientFeedback.idClienteFeedback = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.dataClientFeedback.idAuditoria = me.$route.query.idAuditoria || null
                        me.dataClientFeedback.idUsuario = me.$store.state.user.uid;
                        me.dataClientFeedback.sUsuario = me.$store.state.user.username;
                        me.dataClientFeedback.eu = me.$route.params.eu == 'u' ? 'u' : 'i'

                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                });
        },
        registrarClientFeedback() {

            let me = this;
            me.disabled = true;

            const formData = new FormData();

            formData.append("dataClientFeedback", JSON.stringify(me.dataClientFeedback));
            formData.append("idCliente", me.dataClientFeedback.idCliente);
            formData.append("file1", me.firma1);
            formData.append("file2", me.firma2);
            formData.append("folder", 'pack/documentos-stage-2/gestion-client-feedback-s2');

            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/registrar-client-feedback-s2",
                    formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: `Bearer ${localStorage.token}`
                        }
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });

        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.dataClientFeedback.idCliente = user.uidClient;
            // await this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerClientFeedback();
            }
        }

    }

}
</script>

<style>
.valign-middle {
    vertical-align: middle !important;
}

.disabledTab {
    pointer-events: none;
}
</style>
